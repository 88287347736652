<template>
  <div>
    <el-dialog
      title="参赛协议"
      top="0"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="739px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/12.png" />
        <div class="rich" v-html="html"></div>
        <div class="btn flex_center">
          <div class="box" @click="confirmClick">我已阅读并同意此声明</div>
          <!-- <div class="box" @click="dialogVisible = !dialogVisible">不同意</div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    html: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  mounted() {},

  methods: {
    confirmClick() {
      this.dialogVisible = false
      this.$emit('confirm', true)
    },
    close() {
      this.$emit('close', false)
    }
  },
  watch: {
    isShow(val) {
      this.dialogVisible = val
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__title {
  height: 30px;
  line-height: 30px;
  color: @color_one;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_22;
}
.close {
  top: 21px;
  right: 17px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: absolute;
}
.dialog_content {
  width: 100%;
  max-height: 535px;
  border-radius: 5px;
  .rich {
    color: #333;
    overflow-y: auto;
    max-height: 370px;
    line-height: 25px;
    padding-right: 23px;
    font-size: @font_size_14;
    /* 滚动条宽度 */
    &::-webkit-scrollbar {
      width: 8px;
    }
    /* 滚动条背景颜色 */
    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
    }
    /* 滚动条当前背景颜色 */
    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
    }
  }
  .btn {
    margin-top: 14px;
    .box {
      color: #fff;
      padding: 8px 0;
      cursor: pointer;
      min-width: 182px;
      border-radius: 5px;
      text-align: center;
      background-color: @color_one;
      &:nth-child(1) {
        margin-right: 40px;
      }
    }
  }
}
</style>
