<template>
  <div class="detail content" v-if="info">
    <img v-if="info.type == 2" class="online" src="@/assets/image/img/15.png" />
    <div class="header flex_direction_column">
      <div class="basic_info flex_space_between">
        <div class="basic_info_left">
          <img :src="info.cover" />
        </div>
        <div class="basic_info_center flex_direction_column">
          <div class="basic_title">{{ info.name }}</div>
          <div class="basic_xh flex_direction_column">
            <div class="basic_xh_item flex_wrap">
              <img src="@/assets/image/icon/03.png" />
              <span>{{ info.club_name }}</span>
            </div>
          </div>
          <div class="basic_desc">
            <span>参赛地点：{{ info.province }}·{{ info.city }}·{{ info.address }}</span>
            <span>报名时间：{{ info.sign_start }}</span>
            <span>比赛时间：{{ info.start_time }}</span>
          </div>
        </div>
        <div class="basic_info_right flex_direction_column">
          <div class="basic_info_right_top flex">
            <img src="@/assets/image/icon/08.png" />
            <span>小程序报名</span>
            <div class="code">
              <img class="qr_code" :src="info.share" />
              <p>扫码报名</p>
            </div>
          </div>
          <div class="basic_info_right_middle flex" @click="queryClick">
            <img src="@/assets/image/icon/09.png" />
            <span>报名查询</span>
          </div>
          <div class="basic_info_right_bottom flex">
            <div class="basic_info_right_bottom_box flex">
              <img src="@/assets/image/icon/10.png" />
              <span>浏览{{ info.browses }}</span>
            </div>
            <div class="basic_info_right_bottom_box flex">
              <img src="@/assets/image/icon/11.png" />
              <span>报名{{ info.enroll_num }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="vertical_title flex">组别信息</div>
      <div class="list flex_direction_column">
        <div class="list_item flex_space_between" v-for="item in info.group" :key="item.id">
          <div class="list_item_left flex_direction_column">
            <span>{{ item.name }}</span>
            <span>{{ item.brief }}</span>
            <span v-if="item.price != '0.0'">¥{{ item.price }}</span>
            <span v-else>免费</span>
          </div>
          <div class="list_item_right flex">
            <span
              :style="status(item) == '去报名' ? getStyle(info) : 'background:#EEEEEE;color: #666666;'"
              @click="singUpClick(item)"
              >{{ status(item) }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <RichDetail :info="info"></RichDetail>
    <orderPopup :isShow="isShowOrder" @confirm="confirm" @close="closeOrder"></orderPopup>
    <Declare :html="info.statement" :isShow="dialogVisible" @close="close" @confirm="confirmClick"></Declare>
  </div>
  <Loading v-else></Loading>
</template>

<script>
import statusMinxin from '@/minxin/status.js'
import { checkOrder } from '@/service/api/user'
import { matchDetail } from '@/service/api/match'
import RichDetail from '@/components/rich/detail.vue'
import Declare from '@/components/popup/declare.vue'
import orderPopup from '@/components/popup/order.vue'
export default {
  mixins: [statusMinxin],
  components: { RichDetail, Declare, orderPopup },
  data() {
    return {
      info: '',
      groupId: 0,
      isShow: false,
      isShowOrder: false,
      dialogVisible: false,
      isOrderID: 0,
      cancel: false
    }
  },

  mounted() {
    this.matchDetail()
  },

  methods: {
    async checkOrder() {
      const res = await checkOrder({ id: this.$route.query.id })
      if (res.code == 1) {
        this.isShowOrder = true
        this.isOrderID = res.msg
      } else {
        this.dialogVisible = true
      }
      console.log(res)
    },
    // 赛事详情
    async matchDetail() {
      const res = await matchDetail({ id: this.$route.query.id })
      this.info = res.msg
    },
    singUpClick(item) {
      // if (
      //   this.getText({ state: this.info.state, status: this.info.status }) == '去报名' ||
      //   ('进行中' && this.getGroudText(item) == '去报名')
      // ) {
      //   this.groupId = item.id
      //   this.dialogVisible = true
      // }
      let str = this.getText({ state: this.info.state, status: this.info.status })
      if (str == '去报名' && this.getGroudText(item) == '去报名') {
        this.groupId = item.id
        if (!this.cancel) {
          this.checkOrder()
        } else {
          this.dialogVisible = true
        }
      } else {
        this.$message(str)
      }
    },
    status(item) {
      let str = this.getText({ state: this.info.state, status: this.info.status })
      if (str == '去报名') return this.getGroudText(item)
      return str
    },
    confirm() {
      this.$router.push({
        path: '/match-entry',
        query: { orderId: this.isOrderID }
      })
    },
    confirmClick(val) {
      if (val) {
        sessionStorage.clear()
        this.$router.push({
          path: '/match-entry',
          query: {
            // 组别 id
            id: this.groupId
          }
        })
      }
    },
    closeOrder(val) {
      this.cancel = true
      this.isShowOrder = val
      sessionStorage.clear()
      this.$router.push({
        path: '/match-entry',
        query: {
          // 组别 id
          id: this.groupId
        }
      })
    },
    close(val) {
      this.dialogVisible = val
    },
    queryClick() {
      this.$router.push({
        path: '/match-query',
        query: {
          id: this.$route.query.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail {
  position: relative;
  .online {
    left: 0;
    top: 27px;
    z-index: 99;
    width: 80px;
    height: 31px;
    position: absolute;
  }
  .header {
    border-radius: 3px;
    padding: 43px 40px;
    background-color: #fff;
    .basic_info {
      margin-bottom: 44px;
      align-items: flex-start;

      .basic_info_left {
        width: 368px;
        height: 247px;
        img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .basic_info_center {
        flex: 1;
        margin: 0 23px;
        .basic_title {
          height: 42px;
          color: #000000;
          line-height: 42px;
          letter-spacing: 1px;
          font-size: @font_size_26;
        }
        .basic_xh {
          margin-top: 8px;
          .basic_xh_item {
            margin-right: 10px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 5px;
            }
            span {
              height: 22px;
              line-height: 22px;
              color: @color_seven;
              font-size: @font_size_16;
            }
          }
        }
        .basic_desc {
          height: 82px;
          margin-top: 33px;
          line-height: 30px;
          color: @color_seven;
        }
      }
      .basic_info_right {
        position: relative;
        align-items: flex-end;
        justify-content: flex-end;
        .basic_info_right_top {
          overflow: hidden;
          cursor: pointer;
          &:hover {
            .code {
              height: 340px;
              opacity: 1;
              .qr_code {
                height: 250px;
              }
              p {
                height: 22px;
              }
            }
          }
          img {
            width: 14px;
            height: 14px;
            margin-bottom: 3px;
          }
          span {
            height: 22px;
            margin-left: 5px;
            line-height: 22px;
            font-size: @font_size_16;
          }
          .code {
            top: 15px;
            height: 0;
            right: 0px;
            opacity: 0;
            width: 305px;
            overflow: hidden;
            // height: 340px;
            transition: all 0.3s;
            position: absolute;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-image: url('~@/assets/image/img/17.png');
            .qr_code {
              height: 0;
              width: 250px;
              margin: 35px auto 10px auto;
              border: 2px solid @color_five;
            }
            p {
              height: 0;
              width: 100%;

              display: block;
              text-align: center;
              font-size: @font_size_16;
            }
          }
        }
        .basic_info_right_middle {
          width: 136px;
          height: 42px;
          cursor: pointer;
          margin-top: 43px;
          line-height: 42px;
          border-radius: 3px;
          justify-content: center;
          border: 1px solid @color_one;

          img {
            width: 20px;
            height: 20px;
            margin-bottom: 3px;
          }
          span {
            margin-left: 5px;
            color: @color_one;
            font-size: @font_size_18;
          }
        }
        .basic_info_right_bottom {
          margin-top: 43px;
          .basic_info_right_bottom_box {
            cursor: pointer;
            &:nth-child(1) {
              &::after {
                content: '';
                width: 1px;
                height: 10px;
                display: block;
                margin: 0 23px;
                background-color: #ddd;
              }
            }
            img {
              width: 24px;
              height: 24px;
            }
            span {
              height: 25px;
              margin-left: 7px;
              line-height: 25px;
              color: @color_seven;
              font-size: @font_size_16;
            }
          }
        }
      }
    }
    .list {
      .list_item {
        padding: 24px 33px;
        margin-bottom: 24px;
        background-color: @color_bg_three;
        &:last-child {
          margin-bottom: 0;
        }
        .list_item_left {
          flex: 1;
          margin-right: 33px;
          span {
            &:nth-child(1) {
              line-height: 28px;
              font-size: @font_size_20;
            }
            &:nth-child(2) {
              margin-top: 6px;
              line-height: 22px;
              color: @color_fourteen;
              font-size: @font_size_16;
            }
            &:nth-child(3) {
              margin-top: 15px;
              line-height: 28px;
              color: @color_one;
              font-size: @font_size_20;
            }
          }
        }
        .list_item_right {
          width: 114px;
          cursor: pointer;
          span {
            width: 100%;
            height: 36px;
            color: #fff;
            text-align: center;
            line-height: 36px;
            border-radius: 3px;
            font-size: @font_size_16;
            background-color: @color_one;
          }
        }
      }
    }
  }
}
</style>
